import React from "react"

import Layout from "../components/layout"

import {graphql} from 'gatsby'
import Colors from "../constants/Colors"
import Page from '../components/page'
import { ThemeProvider } from "@material-ui/styles"
import theme from "../constants/Theme"
import WidgetTabCollection from "../components/widgets/TabCollection"


const IndexPage = (props) => {
  //const settings = {storyImage: props.data.datoCmsSetting.defaultStoryImage }
  return (
  <ThemeProvider theme={theme}>
    <Layout widgets={props.data.datoCmsSetting.homePage.widgets}>
      <div style={{backgroundColor: Colors.athensGray}}>
        <Page widgets={props.data.datoCmsSetting.homePage.widgets} settings={props.data.datoCmsSetting} />
        <WidgetTabCollection/>
      </div>
    </Layout>
  </ThemeProvider>
  )
}

export const query = graphql`
  query IndexQuery {
    datoCmsSetting {
      homePage {
        ...Page
      }
      defaultStoryImage {
          url
      }
    }
    allDatoCmsPage {
      edges {
        node {
          id
          title
        }
      }
    }
  }
`

export default IndexPage
